import cart from '@/store/cart';
import product from '@/store/product';
import checkout from '@/store/checkout';
import listing from '@/store/listing';
import MessageTypes from '../constants/enums/MessageTypes';

const state = () => ({
  pageData: {
    type: '',
    globalType: '',
    initial: true,
    replaceType: '',
  },
  metaData: {
  },
  headerData: {
  },
  deviceType: '',
  user_agent: '',
  genuineSlug: '',
  phone: '877-958-9203',
  messages: [],
  protectionData: null,
  currentMenu: '',
  customer: null,
  brands: {},
  footerIsRendered: false,
});

export default {
  state,
  modules: {
    listing,
    cart,
    product,
    checkout,
  },
  mutations: {
    setPageDataType(state, type) {
      state.pageData.type = type;
    },
    setPageDataGlobalType(state, type) {
      state.pageData.globalType = type;
    },
    setPageDataReplaceType(state, type) {
      state.pageData.replaceType = type;
    },
    setMetaData(state, data) {
      state.metaData = { ...state.metaData, ...data };
    },
    setBreadCrumbs(state, breadcrumbs) {
      state.metaData.addons.breadcrumbs = breadcrumbs;
    },
    setHeaderData(state, data) {
      state.headerData = { ...state.headerData, ...data };
    },
    setPageDataInitial(state, value) {
      state.pageData.initial = value;
    },
    setUserAgent(state, value) {
      state.user_agent = value;
    },
    setGenuineSlug(state, slug) {
      state.genuineSlug = slug ? `/${slug}` : '';
    },
    setPhone(state, phone) {
      state.phone = phone;
    },
    setMessages(state, messages) {
      state.messages = messages;
    },
    setFooterRender(state, value) {
      state.footerIsRendered = value;
    },
    /**
     * Add message
     * @param state
     * @param {Object} message - Message data
     * @param {String} message.type - One of MessageTypes
     * @param {String} message.content ['<h4>Error!</h4>'] - Message content, it can be html string
     * @param {Boolean} message.permanent - Display until user closes it or hide after 8 seconds
     */
    addMessage(state, message) {
      const msg = { ...message };
      msg.id = `_${Math.random().toString(36).substr(2, 9)}`;
      if (!Object.values(MessageTypes).includes(msg.type)) {
        msg.type = MessageTypes.SUCCESS;
      }
      state.messages = [...state.messages, msg];
    },
    removeMessage(state, id) {
      state.messages = state.messages.filter((msg) => msg.id !== id);
    },
    setProtectionData(state, value) {
      state.protectionData = value;
    },
    setCurrentMenu(state, value) {
      state.currentMenu = value;
    },
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setBrandsData(state, value) {
      state.brands = value;
    },
    setDeviceType(state, value) {
      state.deviceType = value;
    },
  },
  actions: {
  },
};
